<script setup lang="ts">
import type { Collections, ContentNavigationItem } from '@nuxt/content'
import type { Locale } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    path?: string
    locale?: Locale
    navigation?: boolean
    pageOnly?: boolean
  }>(),
  {
    navigation: true,
    pageOnly: false,
  },
)

const route = useRoute()
const path = computed(() => props.path || route.path)
const { locale: _locale } = useI18n()
const loc = computed(() => props.locale || _locale.value)

const { data: _navigation } = await useAsyncData(
  'content:page-children:' + path.value,
  async () => {
    const collection = getContentCollection('all', loc.value).name as keyof Collections
    const content = await queryCollectionNavigation(collection)
      .where('path', 'LIKE', `${path.value}%`)
      .order('stem', 'ASC')
    return content
  },
  { watch: [loc] },
)

function findObjectsByPath(
  data: ContentNavigationItem[],
  targetPath: string,
): ContentNavigationItem[] {
  if (targetPath === '/') return data
  const result: ContentNavigationItem[] = []
  function search(node: ContentNavigationItem) {
    if (node.path === targetPath) result.push(node)
    if (node.children) node.children.forEach(search)
  }
  data.forEach(search)
  return result
}

function filterValidPages(data: ContentNavigationItem[]): ContentNavigationItem[] {
  return data.filter((item) => item.page !== false)
}

const children = computed<ContentNavigationItem[]>(() => {
  if (_navigation.value) {
    let r = findObjectsByPath(_navigation.value, path.value)
    if (r[0] && r[0].children) {
      r = r[0].children
      if (props.pageOnly) r = filterValidPages(r)
      return r
    }
  }
  return []
})
</script>

<template>
  <nav v-if="props.navigation">
    <ul>
      <li v-for="item of children" :key="item.path">
        <NuxtLink :to="localeLink(item.path, loc)">{{ item.title }}</NuxtLink>
      </li>
    </ul>
  </nav>
  <div v-else>
    <div v-for="item in children" :key="item.path">
      <NuxtLink :to="localeLink(item.path, loc)">{{ item.title }}</NuxtLink>
    </div>
  </div>
  <!-- <DebugDumpArray :items="children" /> -->
</template>
